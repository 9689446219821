@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap");

body {
	font-family: "Plus Jakarta Sans", "Quicksand", sans-serif;
}

/* Styling to remove default arrows buttons for number input  */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
input[type="number"] {
	-moz-appearance: textfield;
}
input:-webkit-autofill,
input:-webkit-autofill:focus {
	background-color: #eef9ff !important;
}

/**** Dashboard Home ****/
.transaction_table tr td {
	padding: 5px 0;
}

@media print {
	.printable-content {
		/* Add styles for printing */
	}
}

/* width */
/* ::-webkit-scrollbar {
	width: 10px;
} */

/* Track */
/* ::-webkit-scrollbar-track {
	background: #f1f1f1;
} */

/* Handle */
/* ::-webkit-scrollbar-thumb {
	background: #888;
	border-radius: 5px;
} */

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
	background: #555;
} */

::-webkit-scrollbar {
	height: 0.3rem;
	width: 0.5rem;
}

.scrollbar-trigger::-webkit-scrollbar {
	width: 0.3rem;
}

.scrollbar-trigger::-webkit-scrollbar-thumb {
	visibility: visible;
	background-color: white;
	border-color: rgba(255, 255, 255, 1);
	border-radius: 9999px;
	border-width: 0.5rem;
}

/* .dark ::-webkit-scrollbar-thumb {
	background-color: hsla(0, 0%, 100%, 0.1);
	background-color: #07122f;
} */

::-webkit-scrollbar-thumb {
	/* --tw-border-opacity: 1; */
	background-color: #07122f;
	border-color: rgba(255, 255, 255, 1);
	border-radius: 9999px;
	border-width: 1px;
	/* visibility: visible !important; */
}

::-webkit-scrollbar-track {
	background-color: transparent;
	border-radius: 9999px;
}

/* styling to remove default background color in input fields when you select data from the autocomplete options */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	-webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
	-webkit-transition-delay: 9999s;
}
