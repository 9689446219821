.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.loading-animation {
	width: 20px;
	height: 20px;
	border-radius: 50%;
	background: rgb(26 61 130);
	box-shadow: 0 0 0 0 #1a3d82;
	animation: pl2 1.5s infinite linear;
	position: relative;
}
.loading-animation:before,
.loading-animation:after {
	content: "";
	position: absolute;
	inset: 0;
	border-radius: inherit;
	box-shadow: 0 0 0 0 #1a3d82;
	animation: inherit;
	animation-delay: -0.5s;
}
.loading-animation:after {
	animation-delay: -1s;
}
@keyframes pl2 {
	100% {
		box-shadow: 0 0 0 40px #0000;
	}
}

.lds-ring {
	display: inline-flex;
	position: relative;
	width: 80px;
	height: 80px;
	transform: scale(0.3);
}
.lds-ring div {
	box-sizing: border-box;
	display: block;
	position: absolute;
	width: 64px;
	height: 64px;
	margin: 8px;
	border: 8px solid #fff;
	border-radius: 50%;
	animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
	animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
	animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
	animation-delay: -0.15s;
}
@keyframes lds-ring {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.processBackDrop {
	box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.5);
	backdrop-filter: blur(15px);
}

.loading-animations .triangle1 {
	animation: separateAndJoin1 3s infinite;
}

.loading-animations .triangle2 {
	animation: separateAndJoin2 3s infinite;
}

.loading-animations .triangle3 {
	animation: separateAndJoin3 3s infinite;
}

@keyframes separateAndJoin1 {
	0%,
	100% {
		transform: translateX(0);
	}
	25% {
		transform: translateX(0);
	}
	50% {
		transform: translateX(60px);
	}
	75% {
		transform: translateX(60px);
	}
}

@keyframes separateAndJoin2 {
	0%,
	100% {
		transform: translateX(0);
	}
	25% {
		transform: translateX(0);
	}
	50% {
		transform: translateX(120px);
	}
	75% {
		transform: translateX(120px);
	}
}

@keyframes separateAndJoin3 {
	0%,
	100% {
		transform: translateX(0);
	}
	25% {
		transform: translateX(0);
	}
	50% {
		transform: translateX(180px);
	}
	75% {
		transform: translateX(180px);
	}
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
	display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}

.mainNav.active {
	border-left: 2px solid transparent;
	background-color: rgba(255, 255, 255, 0.4);
	font-weight: 600;
	border-top-right-radius: 0.375rem;
	border-bottom-right-radius: 0.375rem;
}

.subNav.active {
	color: #fff;
}
